<template>
  <h1>9BOX</h1>
</template>

<script>
export default {
  name: "MatrizNineBox"

}
</script>

<style>

</style>